<div class="content-wrapper" *ngIf="!!role">
  <section class="content-header">
    <h1>Role Detail</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/roles"> Roles</a></li>
      <li class="active"> {{role.tenantName}}</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-12 col-xs-12">
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <div class="form-group" [class.has-error]="!role.roleName">
              <label class="control-label">Role Name</label>
              <input type="text" trim="blur" class="form-control" placeholder="Role Name"
                [(ngModel)]="role.roleName" [disabled]="!role.allowDelete" maxlength="50" required (ngModelChange)="changeAnyAction()">
              <span *ngIf="!role.roleName" class="help-block">Role name is required</span>
            </div>
          </div>
          <div class="col-sm-5 col-xs-12">
            <div class="form-group">
              <label class="control-label">Role Description</label>
              <input type="text" trim="blur" class="form-control" placeholder="Role Description"
                [(ngModel)]="role.roleDescription" [disabled]="!role.allowDelete" maxlength="250" (ngModelChange)="changeAnyAction()">
            </div>
          </div>
          <div class="col-sm-3 col-xs-12 module-service-container">
            <div style="margin-top: 30px;">
              <span>Module: </span>
              <label class="control-label">{{ role.moduleName }}</label>
            </div>
            <div style="margin-top: 30px;" *ngIf="!isDirector && !isAdmin">
              <span>Service Type: </span>
              <label class="control-label">{{ role.serviceType }}</label>
            </div>
            <div style="margin-top: 25px;">
              <button type="button" class="btn btn-primary btn-add-new-role" (click)="duplicateRole()">Duplicate Role</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id"
          (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.name}}</a>
        </li>
        <div class="collapse navbar-collapse pull-right">
          <div>
            <!-- <i class="fa fa-cog fa-spin fa-2x fa-fw" *ngIf="loading"></i> -->
          </div>
        </div>
      </ul>
      <div class="tab-role-content">
        <div class="tab-pane box" id="app_permissions" *ngIf="curTab.id == 'app_permissions'">
          <div class="row">
            <div class="col-md-offset-9 col-md-3">
              <div class="form-group" style="margin-right: 15px;">
                <label class="control-label">Permission Category</label>
                <select [(ngModel)]="role.categoryId" (change)="changeCategory(role)" class="form-control">
                  <option [value]="1">General</option>
                  <option [value]="8">Item</option>
                  <option [value]="4">Drawing</option>
                  <option [value]="5">Administrative</option>
                  <option [value]="6">Security</option>
                  <option [value]="7">Reporting</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Permission Name</th>
                  <th>Description</th>
                  <th class="text-center">
                    <input type="checkbox" [(ngModel)]="role.checkAllViewEdit" (change)="viewAll(role)">
                    View
                  </th>
                  <th class="text-center">
                    <input type="checkbox" [(ngModel)]="role.checkAllAddEdit"
                    [disabled]="!role.checkAllViewEdit" (change)="addAll(role)">
                    Add
                  </th>
                  <th class="text-center">
                    <input type="checkbox" [(ngModel)]="role.checkAllEditEdit"
                      [disabled]="!role.checkAllViewEdit" (change)="editAll(role)">
                    Edit
                  </th>
                  <th class="text-center">
                    <input type="checkbox" [(ngModel)]="role.checkAllDeleteEdit"
                      [disabled]="!role.checkAllViewEdit" (change)="deleteAll(role)">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let per of role.permissions">
                  <td>{{per.permissionName}}</td>
                  <td>{{per.description}}</td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideView" [(ngModel)]="per.allowView"
                      [disabled]="!role.allowDelete || per.disabledView"
                      (change)="viewCheckChange(per.allowView, per)">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideAdd" [(ngModel)]="per.allowAdd"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideEdit" [(ngModel)]="per.allowEdit"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideDelete" [(ngModel)]="per.allowDelete"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="tab-pane box" id="admin_permissions" *ngIf="curTab.id == 'admin_permissions'">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Permission Name</th>
                  <th>Description</th>
                  <th class="text-center">
                    View
                  </th>
                  <th class="text-center">
                    Add
                  </th>
                  <th class="text-center">
                    Edit
                  </th>
                  <th class="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let per of role.adminPermissions">
                  <td>{{per.permissionName}}</td>
                  <td>{{per.description}}</td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideView" [(ngModel)]="per.allowView"
                      [disabled]="!role.allowDelete || per.disabledView"
                      (change)="viewCheckChange(per.allowView, per)">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideAdd" [(ngModel)]="per.allowAdd"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideEdit" [(ngModel)]="per.allowEdit"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" *ngIf="!per.hideDelete" [(ngModel)]="per.allowDelete"
                      [disabled]="!per.allowView || !role.allowDelete" (change)="changePermission()">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
        <div class="tab-pane box" id="users" *ngIf="curTab.id == 'users'">
          <div *ngIf="loadingUser">
            <p class="text-center">
              <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loadingUser"></i>
            </p>
          </div>
          <div class="table-responsive" *ngIf="users && !loadingUser">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th [style.width.px]="40" class="text-center">#</th>
                  <th [style.width.px]="250">Email</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th [style.width.px]="60" *ngIf="!isWorkerRole">QXM Admin</th>
                  <th [style.width.px]="60" *ngIf="!isWorkerRole">QXM Staff</th>
                  <th [style.width.px]="120">Phone</th>
                  <th [style.width.px]="160" *ngIf="!isWorkerRole">Last Login</th>
                  <th [style.width.px]="100" *ngIf="!isWorkerRole">Number of Password Reset</th>
                  <th [style.width.px]="120"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of users; let index = index">
                  <ng-container>
                    <td [style.width.px]="40" class="text-center">{{index +1}}</td>
                    <td [style.width.px]="250"><a routerLink="/{{isWorkerRole?'worker':'user'}}-detail/{{item.userId}}">{{ item.email }}</a>
                    </td>
                    <td>{{ item.firstName }}</td>
                    <td>{{ item.lastName }}</td>
                    <td [style.width.px]="60" *ngIf="!isWorkerRole">{{ item.qxmAdmin ? 'Yes' : 'No' }}</td>
                    <td [style.width.px]="60" *ngIf="!isWorkerRole">{{ item.qxmStaff ? 'Yes' : 'No' }}</td>
                    <td [style.width.px]="120">
                      <ng-container *ngIf="item.phone">({{item.phone | slice:0:3 }})
                        {{item.phone | slice:3:6}}-{{item.phone | slice:6:10}}</ng-container>
                    </td>
                    <td [style.width.px]="160" *ngIf="!isWorkerRole">{{item.lastLogin | date:'MM/dd/yyyy h:mm a'}}</td>
                    <td [style.width.px]="100" *ngIf="!isWorkerRole">{{item.numberOfPasswordReset}}</td>
                    <td [style.width.px]="120">
                      <button type="button" class="btn btn-primary" (click)="roleUnassign = role.roleId;
                        userUnassign = item.userId; modalUnassigned.open();">Remove</button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
            <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()"
              [pagesToShow]="10" [page]="filter.pageNo" [perPage]="filter.pageSize" [count]="pageCount">
            </my-pagination>
          </div>
        </div>
        <div class="tab-pane box" id="accesses" *ngIf="curTab.id == 'accesses'">
          <table class="table table-bordered table-hover table-sm" *ngIf="isWorkerRole">
            <thead>
              <tr>
                <th>Comment + Document Level Access</th>
                <th class="text-center" [style.width.px]="100">
                  <input #ch type="checkbox" [(ngModel)]="checkAllCommentDocumentLevelAccess"
                    (change)="checkAll('comment_document_level_access', checkAllCommentDocumentLevelAccess)" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let x of listCommentDocumentLevelAccess">
                <td class="text-left">{{x.levelName}}</td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="x.checked" (change)="check()" [disabled]="x.alwaysTrue"/>
                </td>
              </tr>
            </tbody>
          </table>

          <hr>

          <table class="table table-bordered table-hover table-sm" *ngIf="!isSchedulingModule && !isDirector && !isAdmin && !isWorkerRole && !isInventory">
            <thead>
              <tr>
                <th>Channel</th>
                <th class="text-center" [style.width.px]="100">
                  <input #ch type="checkbox" [(ngModel)]="checkAllChannel"
                    (change)="checkAll('channel', checkAllChannel)" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of channelList">
                <td class="text-left">{{c.itemName}}</td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="c.checked" (change)="check()" />
                </td>
              </tr>
            </tbody>
          </table>

          <hr>

          <table class="table table-bordered table-hover table-sm">
            <thead class="border-less">
              <tr>
                <th>
                  Document Type Access
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllDocTypeView"
                      (change)="checkAll('doc', checkAllDocTypeView, 'VIEW')" />
                  </p>
                  View
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllDocTypeAdd"
                      (change)="checkAll('doc', checkAllDocTypeAdd, 'ADD')" />
                  </p>
                  Add
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllDocTypeEdit"
                      (change)="checkAll('doc', checkAllDocTypeEdit, 'EDIT')" />
                  </p>
                  Edit
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllDocTypeDelete"
                      (change)="checkAll('doc', checkAllDocTypeDelete, 'DELETE')" />
                  </p>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of docTypeList">
                <td>{{d.documentTypeName}}</td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="d.allowView" (change)="check(true, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="d.allowAdd" (change)="check(true, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="d.allowEdit" (change)="check(true, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="d.allowDelete" (change)="check(true, true)" />
                </td>
              </tr>
            </tbody>
          </table>

          <hr>

          <table class="table table-bordered table-hover table-sm">
            <thead class="border-less">
              <tr>
                <th>Comment Type Access</th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllCommentTypeView"
                      (change)="checkAll('cmt', checkAllCommentTypeView, 'VIEW')" />
                    View
                  </p>
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllCommentTypeAdd"
                      (change)="checkAll('cmt', checkAllCommentTypeAdd, 'ADD')" />
                    Add
                  </p>
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllCommentTypeEdit"
                      (change)="checkAll('cmt', checkAllCommentTypeEdit, 'EDIT')" />
                    Edit
                  </p>
                </th>
                <th class="text-center" [style.width.px]="100">
                  <p class="checkbox-all">
                    <input type="checkbox" [(ngModel)]="checkAllCommentTypeDelete"
                      (change)="checkAll('cmt', checkAllCommentTypeDelete, 'DELETE')" />
                    Delete
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of cmtTypeList">
                <td>{{c.name}}</td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="c.allowView" (change)="check(true, false, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="c.allowAdd" (change)="check(true, false, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="c.allowEdit" (change)="check(true, false, true)" />
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" [(ngModel)]="c.allowDelete" (change)="check(true, false, true)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane box" id="sys_permissions" *ngIf="curTab.id == 'sys_permissions'">
          <div class="table-responsive">
            <div *ngIf="!role.readOnly" class="col-md-12">
              <!-- <div class="checkbox">
                <label>
                  <input type="checkbox" [(ngModel)]="role.estimator" (change)="changeCheckEstimatorEdit(role);changeAnyAction();"
                    [disabled]="!role.allowDelete"> Estimator
                </label>
              </div> -->
              <div class="checkbox">
                <label>
                  <input type="checkbox" [(ngModel)]="role.qaTester" [disabled]="!role.allowDelete" (change)="changeAnyAction()"> QA Tester (Special Roles)
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" [(ngModel)]="role.qxmOnly" [disabled]="!role.allowDelete" (change)="changeAnyAction()"> QXM Only (Special Roles)
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" [(ngModel)]="role.clientVisible" [disabled]="!role.allowDelete" (change)="changeAnyAction()"> Client Visible
                </label>
              </div>
            </div>

            <table class="table table-bordered table-hover table-sm">
              <thead class="border-less">
                <tr>
                  <th class="text-center" [style.width.px]="100">Assigned</th>
                  <th class="text-center" [style.width.px]="200">
                    License Types
                  </th>
                  <th class="text-center">
                    Descriptions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of licenseTypes">
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" [(ngModel)]="c.assigned"
                    (change)="assignOrUnassign()"/>
                  </td>
                  <td [style.width.px]="200">
                    {{c.licenseType}}
                  </td>
                  <td>
                    {{c.description}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-primary" style="margin-top: 16px" *ngIf="role.allowDelete"
      (click)="editRole(role)" [disabled]="!enableBtnSave">Save</button>
    <button type="button" class="btn btn-default" style="margin: 16px 0 0 8px" *ngIf="role.allowDelete"
      (click)="rolesIdDelete = role.roleId; modalDelete.open()">Delete</button>
  </section>
</div>

<bs-modal #modalDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to delete this Role?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalDelete.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="deleteRole()">Delete</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalUnassigned>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this user?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalUnassigned.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="unAssigned(); modalUnassigned.close();">Submit</button>
  </bs-modal-footer>
</bs-modal>
