import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class PermissionService {
  constructor(private http: AuthHttp) {
  }

  getRolesWebAdmin(tenantId, moduleId): Promise<any> {
    return this.http.get('Permission/GetRolesWebAdmin/' + tenantId + '/' + moduleId).toPromise();
  }

  getRoleDetailWebAdmin(roleId): Promise<any> {
    return this.http.get('Permission/GetRoleDetailWebAdmin/' + roleId).toPromise();
  }

  getGlobalRoles(): Promise<any> {
    return this.http.get('Permission/GetGlobalRoles').toPromise();
  }

  getAdminPermissions(): Promise<any> {
    return this.http.get('Permission/GetAdminPermissions').toPromise();
  }

  addRoleWithPermissions(info): Promise<any> {
    return this.http.put('Permission/AddRoleWithPermissions', JSON.stringify(info)).toPromise();
  }

  addRoleNoPermissions(info): Promise<any> {
    return this.http.put('Permission/AddRoleNoPermissions', JSON.stringify(info)).toPromise();
  }

  addGlobalRoleWithPermissions(info): Promise<any> {
    return this.http.put('Permission/AddGlobalRoleWithPermissions', JSON.stringify(info)).toPromise();
  }

  editRoleWithPermissions(info): Promise<any> {
    return this.http.post('Permission/EditRoleWithPermissions', JSON.stringify(info)).toPromise();
  }


  editGlobalRoleWithPermissions(info): Promise<any> {
    return this.http.post('Permission/EditGlobalRoleWithPermissions', JSON.stringify(info)).toPromise();
  }

  deleteRole(id): Promise<any> {
    return this.http.delete('Permission/DeleteRole/' + id).toPromise();
  }

  getGlobalPermissions(): Promise<any> {
    return this.http.get('Permission/GetGlobalPermissions/').toPromise();
  }

  getTenantsRolesOfUser(): Promise<any> {
    return this.http.get('Permission/GetTenantsRolesOfUser').toPromise();
  }

  getPermissionModules(): Promise<any> {
    return this.http.get('Permission/GetPermissionModules').toPromise();
  }

  getPermissionById(id): Promise<any> {
    return this.http.get(`Permission/GetPermissionById/${id}`).toPromise();
  }

  updatePermissionModules(info): Promise<any> {
    return this.http.put('Permission/UpdatePermissionModules', JSON.stringify(info)).toPromise();
  }

  updatePermissionOrders(info): Promise<any> {
    return this.http.put('Permission/UpdatePermissionOrders', JSON.stringify(info)).toPromise();
  }

  getAllDocumentType(): Promise<any> {
    return this.http.get('Documents/GetAllDocumentTypeAdmin').toPromise();
  }

  getListCommentTypes(): Promise<any> {
    return this.http.get('Comments/GetAllCommentTypesAdmin').toPromise();
  }

  duplicateRole(roleId, tenantId = 0): Promise<any> {
    return this.http.post(`Permission/DuplicateRole/${roleId}/${tenantId}`).toPromise();
  }
}
