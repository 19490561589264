import { OnInit, ChangeDetectorRef, HostListener, ViewChild, Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SettingService } from '../../services/setting.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
declare var $: any;
import * as _ from 'lodash';
import { Config } from '@app-admin/config';

@Component({
  template: ''
})
export abstract class CommonPricingComponent implements OnInit {
    @ViewChild('modalUpdateStatusBatchVersions') modalUpdateStatusBatchVersions: BsModalComponent;
    loadingTenant: any;
    tenants: any;
    tileTenants: any;
    activeTenants: any[];
    activeTileTenants: any[];
    tenantId = 0;
    tenantName = "";
    lastUpdate = "";
    lastUpdateNewest = "";
    showWarning = false;
    showInprogress = false;
    inprogressPercent = 0;
    isTenantOnly: boolean = false;

    showError = false;
    statusBatchVersionsForm: FormGroup;
    tabs = [];
    curTab = null;

    constructor(fb: FormBuilder, public toast: ToastrService, public settingService: SettingService,
        public userService: UserService, public cdr: ChangeDetectorRef) {
        this.isTenantOnly = this.userService.isTenantOnly();
        this.statusBatchVersionsForm = fb.group({
            batchVersionId: [''],
            batchName: [''],
            note: [''],
            tenantId: ['']
        });
    }

    @HostListener('resize', ['$event'])
    onResize(event) {
        this.calcHeightTable(this.curTab.id);
    }

    changeTab(tab) {
        $('.tab-pane.box').removeClass('active');
        this.curTab = tab;
        setTimeout(() => {
            $('#' + this.curTab.id).addClass('active');
            this.loadStatusBatchVersions();
            this.calcHeightTable(this.curTab.id);
        });
    }

    calcHeightTable(id: string, scrollTop: number = 0) {
        if (id == this.curTab.id) {
            setTimeout(() => {
                try {
                    let maxHeightOfTable = $(window).height() - $('#' + id + ' .table-responsive tbody').offset().top - $('footer').outerHeight() - 31;
                    $('#' + id + ' .table-responsive tbody').css('max-height', maxHeightOfTable);
                    let widthOfScrollBar = $('#' + id + ' .table-responsive tbody').width() - $('#' + id + ' .table-responsive tbody tr').width();
                    $('#' + id + ' .table-responsive thead').width('calc(100% - ' + widthOfScrollBar + 'px)');
                    $('#' + id + ' .table-responsive tbody').scrollTop(scrollTop);
                }
                catch{ }
            });
        }
    }

    ngOnInit(businessUnitId=Config.BusinessUnitId.Slab) {
        $('body').layout('fix');
        if (sessionStorage.getItem('tenantId')) {
            this.tenantId = Number(sessionStorage.getItem('tenantId'));
            this.statusBatchVersionsForm.get('tenantId').setValue(this.tenantId);
        }
        this.loadStatusBatchVersions();
        this.loadingTenant = true;
        this.settingService.getTenant().then(response => {
            if (response.status) {
                this.tenants = response.data.filter(x => x.isSlabService);
                this.tileTenants = response.data.filter(x=>x.isTileService);
                if(businessUnitId == Config.BusinessUnitId.Tile){
                    if(!this.tileTenants.includes(this.tenantId)){
                        this.tenantId = 0;
                    }
                }
                this.activeTenants = this.tenants.filter(x => x.active);
                this.activeTileTenants = this.tileTenants.filter(x => x.active);
                if (this.isTenantOnly) {
                    const accessTenants = this.userService.tenants;
                    this.tenants = _.filter(this.tenants, function(o) {
                        return o.tenantId > 0 && _.indexOf(accessTenants, o.tenantId) !== -1;
                    });
                }
            }
            else this.toast.error('Can\'t get list tenant');
            this.loadingTenant = false;
        });

        this.loadingTenant = true;
        setTimeout(() => {
            this.loadingTenant = false;
            this.cdr.detectChanges();
            $('#' + this.tabs[0].id).addClass('active');
        }, 10);
    }

    changeTenant() {
        sessionStorage.setItem('tenantId', this.tenantId.toString());
        this.statusBatchVersionsForm.get('tenantId').setValue(this.tenantId);
        this.loadStatusBatchVersions();
        this.loadingTenant = true;
        setTimeout(() => {
            this.loadingTenant = false;
            this.cdr.detectChanges();
            $('#' + this.curTab.id).addClass('active');
        }, 10);
    }
    changeData(){
        this.loadingTenant = true;
        setTimeout(() => {
            this.loadingTenant = false;
            this.cdr.detectChanges();
            $('#' + this.curTab.id).addClass('active');
        }, 10);
    }

    loadStatusBatchVersions() {
        // if (this.tenantId <= 0) return;
        // this.settingService.getStatusBatchVersions(this.tenantId).then(response => {
        //     if (response.status && response.data) {
        //         this.tenantName = response.data.tenantName;
        //         this.lastUpdate = response.data.lastUpdate;
        //         this.lastUpdateNewest = response.data.lastUpdateNewest;
        //         this.statusBatchVersionsForm.patchValue({
        //             batchVersionId: response.data.batchVersionId,
        //             batchName: this.tenantName + ' ' + new DatePipe('en-US').transform(this.lastUpdate, 'hh:mm a MM/dd/yyyy'),
        //             note: response.data.note
        //         });
        //         if (response.data.batchStatus == 1) {
        //             this.showWarning = false;
        //             this.showInprogress = true;
        //             this.showError = false;
        //             this.inprogressPercent = response.data.inprogressPercent;
        //         }
        //         else if (response.data.batchStatus == 0) {
        //             this.showWarning = true;
        //             this.showInprogress = false;
        //             this.showError = false;
        //         }
        //         else if (response.data.batchStatus == 3) {
        //             this.showWarning = false;
        //             this.showInprogress = false;
        //             this.showError = true;
        //         }
        //         this.calcHeightTable(this.curTab.id);
        //     }
        //     else {
        //         this.showWarning = false;
        //         this.showInprogress = false;
        //         this.showError = false;
        //         this.calcHeightTable(this.curTab.id);
        //         this.tenantName = "";
        //         this.lastUpdate = "";
        //         this.lastUpdateNewest = "";
        //         this.statusBatchVersionsForm.patchValue({
        //             batchVersionId: '',
        //             batchName: '',
        //             note: ''
        //         });
        //     }
        // });
    }

    updateStatus() {
        this.statusBatchVersionsForm.disable();
        this.settingService.updateStatusBatchVersions(this.statusBatchVersionsForm.value, false).then(response => {
            if (response.status) {
                this.statusBatchVersionsForm.enable();
                this.modalUpdateStatusBatchVersions.close();
                this.toast.success(response.message);
                this.loadStatusBatchVersions();
            }
            else {
                this.toast.error(response.message);
            }
        });
    }

    republishBatch() {
        this.settingService.rerunBatchVersions(this.tenantId).then(response => {
            if (response.status) {
                this.toast.success('Batch has been Republish');
            }
            else response.message ? this.toast.error(response.message) : this.toast.error('Republish batch failed');
        });
    }
}
